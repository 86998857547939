/**
 * https://www.npmjs.com/package/@googlemaps/react-wrapper
 */

import { useEffect, useRef } from "react";

export default function Map(props) {
  const ref = useRef();

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center: {
        lat: props.location.geometry.lat,
        lng: props.location.geometry.lng,
      },
      zoom: 9,
      maxZoom: 9,
      disableDefaultUI: false,
      fullscreenControl: false,
    });

    new window.google.maps.Circle({
      strokeColor: "black",
      strokeOpacity: 0,
      strokeWeight: 2,
      fillColor: "blue",
      fillOpacity: 0.3,
      map,
      center: {
        lat: props.location.geometry.lat,
        lng: props.location.geometry.lng,
      },
      radius: 20000,
    });
  }, [props.location.geometry.lat, props.location.geometry.lng]);

  return (
    <>
      <div ref={ref} id="map" style={{ height: "400px" }} />
    </>
  );
}
