import { MoreVert } from "@mui/icons-material";
import {
  Divider,
  Dropdown,
  Grid,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
} from "@mui/joy";
import IconButton from "@mui/material/IconButton";
import { getAuth } from "firebase/auth";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useGetConfigQuery } from "../../services/rtk/osare";

function ResponsiveAppBar(props) {
  const navigate = useNavigate();
  const { data: configData } = useGetConfigQuery();
  const auth = getAuth();

  React.useEffect(() => {
    console.log("configData", configData);
  }, [configData]);

  return (
    <Grid container>
      <Grid xs={10}>
        <Typography level="h3">{configData?.name ?? "..."}</Typography>
      </Grid>
      <Grid xs={2} textAlign={"right"}>
        <Dropdown>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{ root: { variant: "outlined", color: "neutral" } }}
          >
            <MoreVert />
          </MenuButton>
          <Menu placement="bottom-end">
            {auth.currentUser && (
              <>
                <MenuItem>
                  <b>{auth.currentUser.displayName}</b>
                </MenuItem>
                <Divider />
              </>
            )}
            <MenuItem
              onClick={() => {
                navigate("/my/bookings");
              }}
            >
              My Bookings
            </MenuItem>
            <MenuItem
              onClick={() => {
                auth.signOut();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Dropdown>
      </Grid>
    </Grid>
  );
}
export default ResponsiveAppBar;
