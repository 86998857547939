import { Container, Link, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import ResponsiveAppBar from "../../components/Appbar/Appbar";
import WrapperContainer from "../../components/WrapperContainer/WrapperContainer";

export default function Confirmed() {
  const navigate = useNavigate();

  return (
    <WrapperContainer>
      <ResponsiveAppBar />
      <Container>
        <Typography marginTop={5} level="title-lg" textAlign={"center"}>
          Thank you - we've got your booking!
        </Typography>

        <Typography marginTop={2} textAlign={"center"}>
          <Link onClick={() => navigate("/my/bookings")}>
            View the status of your booking
          </Link>
        </Typography>
      </Container>
    </WrapperContainer>
  );
}
