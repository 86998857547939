/* eslint-disable no-unused-vars */
import {
  Alert,
  CircularProgress,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  Stack,
  Typography,
} from "@mui/joy";
import React from "react";
import { useCmsDocumentByIdQuery } from "../../services/rtk/osare";

export default function DocumentViewerModal(props) {
  const {
    data: documentData,
    isLoading,
    isFetching,
    isError,
    error,
  } = useCmsDocumentByIdQuery({
    id: props.documentId,
  });

  return (
    <React.Fragment>
      <Stack direction="row" spacing={1}></Stack>
      <Modal open={props.open} onClose={() => props.setOpenFn(false)}>
        <ModalOverflow>
          <ModalDialog
            aria-labelledby="modal-dialog-overflow"
            layout={"center"}
          >
            <ModalClose />
            {(isLoading || isFetching) && <CircularProgress />}

            {!isError ? (
              <>
                <Typography id="modal-dialog-overflow" level="h2">
                  {documentData?.results?.document.title}
                </Typography>
                <div
                  dangerouslySetInnerHTML={{
                    __html: documentData?.results?.document.body,
                  }}
                />
              </>
            ) : (
              <>
                <Typography id="modal-dialog-overflow" level="h2">
                  Sorry...
                </Typography>
                <Alert color="danger">No document found!</Alert>
              </>
            )}
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </React.Fragment>
  );
}
