import { LocationOn } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";

export default function BookingEvent(props) {
  return (
    <>
      {props.listingData.eventData ? (
        <Paper sx={{ padding: 2 }} variant="outlined">
          <Typography fontWeight={"bold"}>
            {props.listingData.eventData[0].name}
          </Typography>
          <Typography>{props.listingData.eventData[0].tagline}</Typography>
          <Typography>
            <LocationOn sx={{ verticalAlign: "middle" }} />{" "}
            {props.listingData.eventData[0].location.formattedAddress}
          </Typography>
        </Paper>
      ) : (
        <Typography>
          No event data - the listing may have been deleted.
        </Typography>
      )}
    </>
  );
}
