import { LocationOn } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";

export default function BookingAccommodation(props) {
  const mapRoomDatatoRoomId = (roomId) => {
    const roomData = props.listingData?.roomData?.find((a) => roomId === a._id);

    return roomData;
  };

  const mapAccommodationDatatoAccommodationId = (accommodationId) => {
    const accommodationData = props.listingData.accommodationData.find(
      (a) => accommodationId === a._id
    );

    return accommodationData;
  };

  return (
    <>
      {props.listingData?.roomData ? (
        <Paper sx={{ padding: 2 }} variant="outlined">
          <Typography fontWeight={"bold"}>
            {props.inventoryQuantity}x {mapRoomDatatoRoomId(props.roomId).name}{" "}
            in{" "}
            {mapAccommodationDatatoAccommodationId(props.accommodationId).name}
          </Typography>
          <Typography>
            <LocationOn sx={{ verticalAlign: "middle" }} />{" "}
            {
              mapAccommodationDatatoAccommodationId(props.accommodationId)
                .location.formattedAddress
            }
          </Typography>
        </Paper>
      ) : (
        <Typography>
          No accommodation data - the listing may have been deleted.
        </Typography>
      )}
    </>
  );
}
