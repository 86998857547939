import { Typography } from "@mui/joy";
import { DateTime } from "luxon";

export default function BookingDateRange(props) {
  console.log("BookingDateRange", props);
  const availabilityItem = props.orderData.listingData.availabilityData.find(
    (a) => a._id === props.orderData.availabilityId
  );
  return (
    <Typography>
      {DateTime.fromMillis(availabilityItem.fromTimestamp).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      )}{" "}
      -{" "}
      {DateTime.fromMillis(availabilityItem.toTimestamp).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      )}
    </Typography>
  );
}
