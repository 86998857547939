import {
  CheckRounded,
  ClearRounded,
  EventBusy,
  FoodBank,
  FreeBreakfast,
  Info,
  LocalDining,
  Recycling,
  Warning,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import draftToHtml from "draftjs-to-html";
import { getAuth } from "firebase/auth";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ResponsiveAppBar from "../../components/Appbar/Appbar";
import ChipCluster from "../../components/ChipCluster/ChipCluster";
import Gallery from "../../components/Gallery/Gallery";
import Map from "../../components/Map/Map";
import RoomBlock from "../../components/RoomBlock/RoomBlock";
import WrapperContainer from "../../components/WrapperContainer/WrapperContainer";
import {
  useCreateCustomerMutation,
  useLazyGetStorefrontListingQuery,
} from "../../services/rtk/osare";

export default function Listing() {
  let [searchParams] = useSearchParams();
  const auth = getAuth();
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [selectedDate, setSelectedDate] = useState(-1);
  const [createCustomer, createCustomerResult] = useCreateCustomerMutation();
  const [customerId, setCustomerId] = useState(false);
  const navigate = useNavigate();
  const [getListing, { data: listingData, isFetching, isLoading, error }] =
    useLazyGetStorefrontListingQuery();
  const [hasAvailability, setHasAvailability] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((authResult) => {
      if (!authResult) {
        navigate(
          `/login?returnTo=${encodeURIComponent(
            `/listing?listingId=${searchParams.get("listingId")}`
          )}`
        );
      } else {
        setLoggedInUser(authResult);
        getListing(searchParams.get("listingId"));
      }
    });
  }, [auth, getListing, navigate, searchParams, setLoggedInUser]);

  useEffect(() => {
    if (listingData && listingData.results) {
      document.title = `${listingData.results[0].name} | ${listingData.results[0].config.name}`;
      createCustomer({
        merchantStripeAccountId:
          listingData.results[0].merchantAccount.stripeAccountId,
        merchantFirebaseUserId: listingData.results[0].firebaseUserId,
      });

      /**
       * Is there any availability? Set a flag
       */
      const availabilityCount = listingData.results[0].availabilityData.filter(
        (a) => a.fromTimestamp > Date.now()
      );

      setHasAvailability(availabilityCount.length > 0);
    }
  }, [createCustomer, listingData]);

  useEffect(() => {
    if (
      createCustomerResult.status === "fulfilled" &&
      createCustomerResult.data
    ) {
      setCustomerId(createCustomerResult.data?.results[0].stripeCustomerId);
    }
  }, [createCustomerResult]);

  const ListingComponent = ({ listingData }) => {
    const listing = listingData?.results[0];

    if (!listing || listingData.error) {
      return null;
    }

    return (
      <Grid container>
        <Grid xs={12}>
          <Typography level="title-lg">{listing.name}</Typography>
          <Typography level="title-md">{listing.tagline}</Typography>
          <Box sx={{ m: 2 }} />

          <Grid xs={12}>
            <Gallery collection="listings" collectionId={listing._id} />
          </Grid>

          <Box sx={{ m: 2 }} />

          <Box marginBottom={2}>
            <Typography level="title-md">
              <div
                dangerouslySetInnerHTML={{
                  __html: draftToHtml(listing.introduction),
                }}
              />
            </Typography>
          </Box>

          <Box>
            <Typography level="title-md">Highlights</Typography>
            <Typography level="body-sm">
              <div
                dangerouslySetInnerHTML={{
                  __html: draftToHtml(listing.highlights),
                }}
              />
            </Typography>
          </Box>

          {/**
           * Event Data
           */}

          <Box sx={{ m: 2 }}>
            <Divider>Event</Divider>
          </Box>

          <Box>
            {listing.eventData.map((event, idx) => {
              return (
                <>
                  <Grid container key={`event-${event._id}}`}>
                    <Grid xs={12}>
                      <Typography level="title-lg">{event.name}</Typography>
                      <Typography level="body-sm">
                        Language: {event.spokenLanguages.join(", ")}
                      </Typography>
                      <Typography gutterBottom level="body-sm">
                        Skill levels: {event.skillLevels.join(", ")}
                      </Typography>

                      <Typography level="title-md">{event.tagline}</Typography>

                      <Box marginY={2}>
                        <Gallery collection="events" collectionId={event._id} />
                      </Box>

                      <Typography>{event.introduction}</Typography>

                      {event.location.addressComponents && (
                        <Box marginY={2}>
                          <Typography gutterBottom level="title-lg">
                            Location
                          </Typography>
                          <Typography level="body-md">
                            Area:{" "}
                            {event.location.addressComponents[2].short_name}
                          </Typography>
                          <Typography gutterBottom level="body-sm">
                            After booking, we'll give you the exact location.
                          </Typography>
                          <Map location={event.location} />
                        </Box>
                      )}

                      <Box marginY={2}>
                        <Typography level="title-md">Highlights</Typography>
                        <Typography level="body-sm">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: draftToHtml(listing.highlights),
                            }}
                          />
                        </Typography>
                      </Box>

                      {event.eventDaysLength > 0 && (
                        <Box marginY={2}>
                          <Typography level="title-md">
                            Event duration
                          </Typography>
                          <Typography level="body-lg">
                            {event.eventDaysLength} days
                          </Typography>
                        </Box>
                      )}

                      {event.daysWithInstruction > 0 && (
                        <Box marginY={2}>
                          <Typography level="title-md">
                            Event days with instruction
                          </Typography>
                          <Typography level="body-lg">
                            {event.daysWithInstruction} days
                          </Typography>
                        </Box>
                      )}

                      {event.itineraryDayByDay &&
                      event.itineraryDayByDay.length > 0 ? (
                        <Box marginY={2}>
                          <Typography
                            startDecorator={<LocalDining />}
                            level="title-lg"
                          >
                            Itinerary
                          </Typography>
                          {event.itineraryDayByDay
                            .filter((d) => d.description !== "")
                            .map((day, idx) => (
                              <>
                                <Typography>Day {idx + 1}:</Typography>
                                <Typography>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: draftToHtml(day),
                                    }}
                                  />
                                </Typography>
                              </>
                            ))}
                        </Box>
                      ) : null}

                      {event.includedMeals && event.includedMeals.length > 0 ? (
                        <Box marginY={2}>
                          <Typography
                            startDecorator={<LocalDining />}
                            gutterBottom
                            level="title-lg"
                          >
                            Included Meals
                          </Typography>
                          <ChipCluster dataArray={event.includedMeals} />
                        </Box>
                      ) : null}

                      {event.includedDrinks &&
                      event.includedDrinks.length > 0 ? (
                        <Box marginY={2}>
                          <Typography
                            startDecorator={<FreeBreakfast />}
                            gutterBottom
                            level="title-lg"
                          >
                            Included Drinks
                          </Typography>
                          <ChipCluster dataArray={event.includedDrinks} />
                        </Box>
                      ) : null}

                      {event.dietarySupport &&
                      event.dietarySupport.length > 0 ? (
                        <Box marginY={2}>
                          <Typography
                            startDecorator={<FoodBank />}
                            gutterBottom
                            level="title-lg"
                          >
                            Supported dietary requirements
                          </Typography>
                          <ChipCluster dataArray={event.dietarySupport} />
                        </Box>
                      ) : null}

                      <Box marginY={2}>
                        <Typography level="title-lg">
                          Additional information about food and drink
                        </Typography>
                        <Typography level="body-sm">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: draftToHtml(
                                event.additionalFoodDescription
                              ),
                            }}
                          />
                        </Typography>
                      </Box>

                      {event.specificallyIncluded &&
                      event.specificallyIncluded.length > 0 ? (
                        <Box marginY={2}>
                          <Typography
                            startDecorator={<CheckRounded color="success" />}
                            gutterBottom
                            level="title-lg"
                          >
                            Things specifically included by the organiser
                          </Typography>
                          <ChipCluster dataArray={event.specificallyIncluded} />
                        </Box>
                      ) : null}

                      {event.specificallyExcluded &&
                      event.specificallyExcluded.length > 0 ? (
                        <Box marginY={2}>
                          <Typography
                            startDecorator={<ClearRounded color="error" />}
                            gutterBottom
                            level="title-lg"
                          >
                            Things specifically excluded by the organiser
                          </Typography>
                          <ChipCluster dataArray={event.specificallyExcluded} />
                        </Box>
                      ) : null}

                      <Box marginY={2}>
                        <Typography level="title-lg">Things to do</Typography>
                        <Typography level="title-sm">
                          These may cost money
                        </Typography>
                        <Typography level="body-sm">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: draftToHtml(event.thingsToDoPaid),
                            }}
                          />
                        </Typography>
                      </Box>

                      <Box marginY={2}>
                        <Typography level="title-lg">
                          Know before you go
                        </Typography>
                        <Typography level="body-sm">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: draftToHtml(event.preTravelAdvisory),
                            }}
                          />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </Box>

          <Box sx={{ m: 2 }}>
            <Divider>Accommodation</Divider>
          </Box>

          {listing.accommodationData.map((accommodation) => (
            <>
              <Typography level="title-lg">{accommodation.name}</Typography>
              <Typography gutterBottom level="body-sm">
                {accommodation.styles.join(", ")}
              </Typography>

              <Box marginY={2}>
                <Gallery
                  collection="accommodation"
                  collectionId={accommodation._id}
                />
              </Box>

              <Box marginY={2}>
                <Typography level="title-lg">Description</Typography>
                <Typography level="body-sm">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: draftToHtml(accommodation.description),
                    }}
                  />
                </Typography>
              </Box>

              {accommodation.location.addressComponents &&
                accommodation.location.addressComponents.length > 1 && (
                  <Box marginY={2}>
                    <Typography gutterBottom level="title-lg">
                      Location
                    </Typography>
                    <Typography level="body-md">
                      {accommodation.location.addressComponents[2].short_name}
                    </Typography>
                    <Typography gutterBottom level="body-sm">
                      After booking, we'll give you the exact location.
                    </Typography>
                    <Map location={accommodation.location} />
                  </Box>
                )}

              {accommodation.faBasics && accommodation.faBasics.length > 0 ? (
                <Box marginY={2}>
                  <Typography
                    startDecorator={<CheckRounded />}
                    gutterBottom
                    level="title-lg"
                  >
                    Basics
                  </Typography>
                  <ChipCluster dataArray={accommodation.faBasics} />
                </Box>
              ) : null}

              {accommodation.faSustainability &&
              accommodation.faSustainability.length > 0 ? (
                <Box marginY={2}>
                  <Typography
                    startDecorator={<Recycling />}
                    gutterBottom
                    level="title-lg"
                  >
                    Sustainability
                  </Typography>
                  <ChipCluster dataArray={accommodation.faSustainability} />
                </Box>
              ) : null}

              {accommodation.faServices &&
              accommodation.faServices.length > 0 ? (
                <Box marginY={2}>
                  <Typography
                    startDecorator={<CheckRounded />}
                    gutterBottom
                    level="title-lg"
                  >
                    Services
                  </Typography>
                  <ChipCluster dataArray={accommodation.faServices} />
                </Box>
              ) : null}

              {accommodation.faIndoor && accommodation.faIndoor.length > 0 ? (
                <Box marginY={2}>
                  <Typography
                    startDecorator={<CheckRounded />}
                    gutterBottom
                    level="title-lg"
                  >
                    Indoor spaces and facilities
                  </Typography>
                  <ChipCluster dataArray={accommodation.faIndoor} />
                </Box>
              ) : null}

              {accommodation.faOutdoor && accommodation.faOutdoor.length > 0 ? (
                <Box marginY={2}>
                  <Typography
                    startDecorator={<CheckRounded />}
                    gutterBottom
                    level="title-lg"
                  >
                    Outdoor spaces and facilities
                  </Typography>
                  <ChipCluster dataArray={accommodation.faOutdoor} />
                </Box>
              ) : null}

              {accommodation.faFoodDrink &&
              accommodation.faFoodDrink.length > 0 ? (
                <Box marginY={2}>
                  <Typography
                    startDecorator={<CheckRounded />}
                    gutterBottom
                    level="title-lg"
                  >
                    Food &amp; Drink
                  </Typography>
                  <ChipCluster dataArray={accommodation.faFoodDrink} />
                </Box>
              ) : null}

              {accommodation.faActivitiesFitness &&
              accommodation.faActivitiesFitness.length > 0 ? (
                <Box marginY={2}>
                  <Typography
                    startDecorator={<CheckRounded />}
                    gutterBottom
                    level="title-lg"
                  >
                    Activities &amp; Fitness
                  </Typography>
                  <ChipCluster dataArray={accommodation.faActivitiesFitness} />
                </Box>
              ) : null}

              {accommodation.faSpaBeauty &&
              accommodation.faSpaBeauty.length > 0 ? (
                <Box marginY={2}>
                  <Typography
                    startDecorator={<CheckRounded />}
                    gutterBottom
                    level="title-lg"
                  >
                    Spa &amp; Beauty
                  </Typography>
                  <ChipCluster dataArray={accommodation.faSpaBeauty} />
                </Box>
              ) : null}

              {accommodation.faRentals && accommodation.faRentals.length > 0 ? (
                <Box marginY={2}>
                  <Typography
                    startDecorator={<CheckRounded />}
                    gutterBottom
                    level="title-lg"
                  >
                    Rentals
                  </Typography>
                  <ChipCluster dataArray={accommodation.faRentals} />
                </Box>
              ) : null}
            </>
          ))}

          {hasAvailability ? (
            <>
              <Box sx={{ m: 2 }}>
                <Divider>Check availability and book</Divider>
              </Box>

              <Card variant="soft" color="success">
                <Typography level="title-lg" gutterBottom>
                  Choose an available date
                </Typography>
                <Select
                  value={selectedDate}
                  onChange={(e, v) => {
                    console.log(v);
                    setSelectedDate(v);
                  }}
                  placeholder="Choose a date..."
                >
                  {listing.availabilityData
                    .filter((a) => a.fromTimestamp > Date.now())
                    .map((a, idx) => {
                      return (
                        <Option
                          disabled={a.fromTimestamp < Date.now()}
                          key={`date-${idx}`}
                          value={a._id}
                        >
                          {moment(a.fromTimestamp).format("ddd, Do MMM 'YY")} -{" "}
                          {moment(a.toTimestamp).format("ddd, Do MMM 'YY")}
                        </Option>
                      );
                    })}
                </Select>
              </Card>
            </>
          ) : (
            <Alert color="warning" startDecorator={<EventBusy />}>
              Sorry, there's currently no availability for this listing. Please
              keep checking back as the organiser may add more dates.
            </Alert>
          )}

          <Box sx={{ m: 2 }} />

          {selectedDate === null ? (
            <Alert startDecorator={<Info />}>
              Please select a date above to see available accommodation to book
            </Alert>
          ) : (
            listing?.availabilityData
              .find((a) => a._id === selectedDate)
              ?.inventory.map((inventory) => {
                console.log("inventory", inventory);
                /**
                 * Any 0 values are not displayed
                 */
                if (inventory.price === 0 || inventory.quantity === 0) {
                  return null;
                } else {
                  return (
                    <RoomBlock
                      listing={listing}
                      availabilityItem={listing.availabilityData.find(
                        (a) => a._id === selectedDate
                      )}
                      inventoryItem={inventory}
                      stripeCustomerId={customerId}
                      merchantStripeAccountId={
                        listing.merchantAccount.stripeAccountId
                      }
                    />
                  );
                }
              })
          )}
        </Grid>
      </Grid>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const RawCodeComponent = ({ listingData }) => {
    return <pre>{JSON.stringify(listingData, "", 2)}</pre>;
  };

  return (
    <>
      <WrapperContainer>
        <ResponsiveAppBar user={loggedInUser} />
        {error ? (
          <Alert startDecorator={<Warning />} color="danger">
            Sorry, we could not find the listing. {error.message}
          </Alert>
        ) : null}
        {isLoading || isFetching ? (
          <Box marginTop={5} textAlign={"center"}>
            <CircularProgress />
          </Box>
        ) : (
          <ListingComponent listingData={listingData} />
        )}
      </WrapperContainer>
    </>
  );
}
