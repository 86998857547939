import { Chip } from "@mui/joy";
import _ from "lodash";

export default function ChipCluster(props) {
  if (!props.dataArray || props.dataArray.length === 0) {
    return null;
  }

  return (
    <>
      {props.dataArray.map((data) => {
        if (typeof data === "object" && data.description) {
          return (
            <Chip
              size="lg"
              sx={{ mr: 1 }}
              key={`chip-${_.uniqueId()}`}
              variant="soft"
              color="primary"
            >
              {data.description}
            </Chip>
          );
        } else {
          return (
            <Chip
              size="lg"
              sx={{ mr: 1 }}
              key={`chip-${_.uniqueId()}`}
              variant="soft"
              color="primary"
            >
              {data}
            </Chip>
          );
        }
      })}
    </>
  );
}
