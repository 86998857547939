import { CreditCard, Warning } from "@mui/icons-material";
import { Alert, Box, Chip, Grid, Typography } from "@mui/joy";
import { calculateDaysToPackageCharge } from "../../utilities/utils";

export default function Policy(props) {
  const PackageChargeDateAdjustmentComponent = () => {
    const calculations = calculateDaysToPackageCharge(props.state);

    return (
      <>
        {calculations.daysTillCharge <= 0 ? (
          <Box marginTop={2}>
            <Alert color="warning">
              <div>
                <Typography
                  marginBottom={1}
                  startDecorator={<Warning />}
                  fontWeight={"bold"}
                >
                  The total amount is due
                </Typography>
                <Typography level="body-sm">
                  {props.state.listing.merchantAccount.legalEntityName}'s policy
                  requires full payment{" "}
                  {props.state.listing.chargeRemainingAmountDaysBeforeTravel}{" "}
                  days before travel. As your selected travel date is less than
                  this period, the full amount is now due
                </Typography>
              </div>
            </Alert>
          </Box>
        ) : null}
      </>
    );
  };

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography gutterBottom level="body-sm">
          {props.state.listing.merchantAccount.legalEntityName} has set the
          following payment terms for this listing
        </Typography>
        <Typography
          marginY={2}
          gutterBottom
          startDecorator={<CreditCard />}
          level="body-sm"
        >
          Charges the remaining package amount{" "}
          <Chip component={"span"} color="primary">
            {" "}
            {props.state.listing.chargeRemainingAmountDaysBeforeTravel} days
          </Chip>{" "}
          before travel
        </Typography>
        <PackageChargeDateAdjustmentComponent props={props} />
        <Typography
          gutterBottom
          marginTop={1}
          level="body-sm"
          fontWeight={"bold"}
        >
          Refunds
        </Typography>
        <Typography gutterBottom level="body-sm">
          {props.state.listing.merchantAccount.legalEntityName} has set the
          following refund terms for this listing. Refund are handled by{" "}
          {props.state.listing.merchantAccount.legalEntityName}, not{" "}
          {props.state.config.name}.
        </Typography>
        {props.state.listing.cancellationRanges.map((range, idx) => (
          <Typography key={idx}>
            {range.day} day{Number(range.day) > 1 ? "s" : ""} after booking:{" "}
            {range.percentage}% refund
          </Typography>
        ))}
        <Typography color="warning">
          After day {props.state.listing.cancellationRanges.length} from when
          the booking was made, no refunds will be given.
        </Typography>

        {/* {props.state.listing
          .chargeRemainingAmountDaysBeforeTravel === 0 &&
        props.state.listing.policyData.refundPercentAt30DaysBeforeTravelDate ===
          0 &&
        props.state.listing.policyData.refundPercentAt60DaysBeforeTravelDate ===
          0 ? (
          <Alert startDecorator={<Warning />} color="warning">
            This policy does not offer refunds.
          </Alert>
        ) : (
          <>
            <Typography
              marginBottom={1}
              startDecorator={<Warning />}
              color="warning"
            >
              The deposit is non-refundable.
            </Typography>
            <Typography
              gutterBottom
              startDecorator={<Percent />}
              level="body-sm"
            >
              <Chip color="primary">
                {
                  props.state.listing.policyData
                    .refundPercentAt14DaysBeforeTravelDate
                }
                %
              </Chip>{" "}
              refund of the remaining cost at{" "}
              <Chip color="primary">14 days</Chip> before travel
            </Typography>
            <Typography
              gutterBottom
              startDecorator={<Percent />}
              level="body-sm"
            >
              <Chip color="primary">
                {
                  props.state.listing.policyData
                    .refundPercentAt30DaysBeforeTravelDate
                }
                %
              </Chip>{" "}
              refund of the remaining cost at{" "}
              <Chip color="primary">30 days</Chip> before travel
            </Typography>
            <Typography startDecorator={<Percent />} level="body-sm">
              <Chip color="primary">
                {
                  props.state.listing.policyData
                    .refundPercentAt60DaysBeforeTravelDate
                }
                %
              </Chip>{" "}
              refund of the remaining cost at{" "}
              <Chip color="primary">60 days</Chip> before travel
            </Typography>
          </>
        )} */}
      </Grid>
    </Grid>
  );
}
