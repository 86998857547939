import { ArrowDownward, Check, HourglassBottom } from "@mui/icons-material";

import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/joy";
import draftToHtml from "draftjs-to-html";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ResponsiveAppBar from "../../components/Appbar/Appbar";
import DocumentViewerModal from "../../components/DocumentViewerModal/DocumentViewerModal";
import Policy from "../../components/Policy/Policy";
import WrapperContainer from "../../components/WrapperContainer/WrapperContainer";
import { useCreateCheckoutSessionMutation } from "../../services/rtk/osare";
import { calculateDaysToPackageCharge } from "../../utilities/utils";

export default function Review() {
  const { state } = useLocation();
  const [documentViewerOpen, setDocumentViewerOpen] = React.useState(false);
  const [documentId, setDocumentId] = React.useState(null);
  const [isLegalAccepted, setIsLegalAccepted] = React.useState(false);

  const openDocumentViewer = (documentId) => {
    setDocumentId(documentId);
    setDocumentViewerOpen(true);
  };

  const bookButtonText = state.listing.ordersMustBeConfirmed
    ? "Request to Book"
    : "Pay and Reserve";

  console.log("State onload:", state);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const RoomAndAccommodationComponent = () => {
    const room = state.listing.roomData.find(
      (room) => room._id === state.roomId
    );
    const accommodation = state.listing.accommodationData.find(
      (a) => a._id === state.accommodationId
    );

    return (
      <Typography textAlign={"center"} level="body-md">
        {state.inventoryItem.meta.sharable ? (
          <Chip component={"span"}>Shared</Chip>
        ) : null}
        {state.roomQuantity}x {room ? room.name : "-"} in{" "}
        {accommodation ? accommodation.name : "-"}
      </Typography>
    );
  };

  const BookingDateComponent = () => {
    return (
      <Typography textAlign={"center"} level="body-md">
        {state.availabilityItem
          ? `${DateTime.fromMillis(
              state.availabilityItem.fromTimestamp
            ).toFormat("cccc d LLLL yyyy")} - ${DateTime.fromMillis(
              state.availabilityItem.toTimestamp
            ).toFormat("cccc d LLLL yyyy")}`
          : null}
      </Typography>
    );
  };

  const TotalPriceComponent = () => {
    const packageFee = state.roomQuantity * state.inventoryItem.price;

    return (
      <>
        <Typography level="body-sm" marginBottom={-2}>
          Total:
        </Typography>
        <Typography fontSize={25} fontFamily={"monospace"}>
          {state.inventoryItem.defaultCurrency} {packageFee}
        </Typography>
        <Typography marginTop={-2} level="body-sm">
          A{" "}
          <Chip component={"span"} color="primary">
            {state.listing.depositPercentage}% deposit
          </Chip>{" "}
          has been set by {state.listing.merchantAccount.legalEntityName}
        </Typography>

        <Typography level="title-md">Payment Breakdown</Typography>
        <Card color="success" variant="soft">
          <Typography marginBottom={-1} fontWeight={"bold"}>
            Due now: {state.listing.depositPercentage}% Deposit
          </Typography>
          <Typography marginBottom={-1} fontSize={25} fontFamily={"monospace"}>
            {state.inventoryItem.defaultCurrency}{" "}
            {(packageFee * state.listing.depositPercentage) / 100}
          </Typography>
        </Card>

        <Card color="success" variant="outlined">
          <Typography marginBottom={-1} fontWeight={"bold"}>
            Remaining cost due{" "}
            {calculateDaysToPackageCharge(state).daysTillCharge <= 0
              ? "now:"
              : state.listing.chargeRemainingAmountDaysBeforeTravel +
                " days (" +
                calculateDaysToPackageCharge(
                  state
                ).travelDateWithPolicy.toLocaleString() +
                ") before travel:"}{" "}
          </Typography>
          <Typography fontSize={25} fontFamily={"monospace"}>
            {state.inventoryItem.defaultCurrency}{" "}
            {packageFee - (packageFee * state.listing.depositPercentage) / 100}
          </Typography>
        </Card>
      </>
    );
  };

  const SetupIntentComponent = () => {
    const [createCheckoutSession, createCheckoutSessionResult] =
      useCreateCheckoutSessionMutation();

    const createCheckoutSessionAction = () => {
      console.log("Creating checkout session action with:", {
        availabilityId: state.availabilityItem._id,
        inventoryId: state.inventoryItem._id,
        inventoryQuantity: state.roomQuantity,
        listingId: state.listingId,
        accommodationId: state.accommodationId,
        roomId: state.roomId,
        stripeCustomerId: state.stripeCustomerId,
        merchantStripeAccountId: state.merchantStripeAccountId,
        policyId: state.listing.policyId,
      });

      createCheckoutSession({
        availabilityId: state.availabilityItem._id,
        inventoryId: state.inventoryItem._id,
        inventoryQuantity: state.roomQuantity,
        listingId: state.listingId,
        accommodationId: state.accommodationId,
        roomId: state.roomId,
        stripeCustomerId: state.stripeCustomerId,
        merchantStripeAccountId: state.merchantStripeAccountId,
        policyId: state.listing.policyId,
      });
    };

    useEffect(() => {
      if (createCheckoutSessionResult.status === "fulfilled") {
        console.log(
          "createCheckoutSessionResult:",
          createCheckoutSessionResult
        );
        window.location.href =
          createCheckoutSessionResult.data.results[0].redirectUrl;
      }

      if (createCheckoutSessionResult.status === "rejected") {
        alert(
          "Sorry, we couldn't create a checkout session for you. We will reload the page for you and please try again."
        );
        // window.location.reload();
      }
    }, [createCheckoutSessionResult]);

    return (
      <>
        {!isLegalAccepted && (
          <Typography textAlign={"center"} mb={2} color="warning">
            Please agree to our terms and conditions to continue
          </Typography>
        )}
        <Button
          fullWidth
          disabled={
            createCheckoutSessionResult.status === "pending" || !isLegalAccepted
          }
          color="success"
          variant="soft"
          onClick={() => createCheckoutSessionAction()}
        >
          {createCheckoutSessionResult.status === "pending"
            ? "Just a moment..."
            : bookButtonText}
        </Button>
      </>
    );
  };

  return (
    <>
      <WrapperContainer>
        <ResponsiveAppBar />

        <Grid xs={12}>
          <Typography marginBottom={5} level="title-lg">
            Review & Book
          </Typography>

          <Typography
            textAlign={"center"}
            margin={2}
            startDecorator={<ArrowDownward />}
          >
            Your chosen event and accommodation:
          </Typography>
          <Card>
            <Typography textAlign={"center"} level="body-lg">
              {state.listing ? state.listing?.name : "-"}
            </Typography>
            <RoomAndAccommodationComponent />
            <Typography textAlign={"center"} level="body-sm">
              <Chip component={"span"} size="lg" color="primary">
                By {state.listing.merchantAccount.legalEntityName}
              </Chip>
            </Typography>
          </Card>

          <Typography
            textAlign={"center"}
            margin={2}
            startDecorator={<ArrowDownward />}
          >
            Your chosen date:
          </Typography>
          <Card>
            <BookingDateComponent />
          </Card>

          <Typography
            textAlign={"center"}
            margin={2}
            startDecorator={<ArrowDownward />}
          >
            {state.listing.merchantAccount.legalEntityName}'s payment policy:
          </Typography>

          <Card>
            <Policy state={state} />
          </Card>

          <Typography
            textAlign={"center"}
            margin={2}
            startDecorator={<ArrowDownward />}
          >
            Your total price:
          </Typography>

          <Card>
            <TotalPriceComponent />
          </Card>
        </Grid>

        {state.listing.askForInsurance && (
          <Grid xs={12}>
            <Typography
              textAlign={"center"}
              margin={2}
              startDecorator={<ArrowDownward />}
            >
              Insurance:
            </Typography>

            <Card color="warning" variant="soft">
              <Typography>
                {state.listing.merchantAccount.legalEntityName} has asked that
                you hold the relevant insurance for this booking. Please ensure
                you have the correct insurance in place before continuing.
              </Typography>
            </Card>
          </Grid>
        )}

        <Grid xs={12}>
          <Typography
            textAlign={"center"}
            margin={2}
            startDecorator={<ArrowDownward />}
          >
            The legal part:
          </Typography>

          <Card>
            <Typography>
              {state.listing.merchantAccount.legalEntityName} has stated the
              following Terms &amp; Conditions for this booking:
            </Typography>
            <Typography level="body-sm">
              <div
                style={{ height: 200, overflow: "scroll" }}
                dangerouslySetInnerHTML={{
                  __html: draftToHtml(state.listing.termsAndConditions),
                }}
              />
            </Typography>

            <Typography>
              By continuing, i agree that i have read{" "}
              {state.listing.merchantAccount.legalEntityName}'s Terms &amp;
              Conditions for this booking, the{" "}
              <Link onClick={() => openDocumentViewer("terms_of_service")}>
                Website Terms of Service
              </Link>{" "}
              and{" "}
              <Link onClick={() => openDocumentViewer("privacy_policy")}>
                Privacy Policy
              </Link>
            </Typography>
            <Divider />
            <Checkbox
              checked={isLegalAccepted}
              onChange={(e) => setIsLegalAccepted(e.target.checked)}
              label={`I understand and accept ${state.listing.merchantAccount.legalEntityName}'s Terms & Conditions for this booking, the Terms of Service and Privacy Policy above`}
            />
          </Card>
        </Grid>

        <Grid xs={12}>
          {state.listing.ordersMustBeConfirmed ? (
            <Box marginY={5} textAlign={"center"}>
              <HourglassBottom sx={{ width: 50, height: 50 }} />
              <Typography textAlign={"center"} marginX={5}>
                {state.listing.merchantAccount.legalEntityName} will confirm
                this booking first before any payments are taken
              </Typography>
            </Box>
          ) : (
            <Box marginY={5} textAlign={"center"}>
              <Check sx={{ width: 50, height: 50 }} />
              <Typography textAlign={"center"} marginX={5}>
                This booking will be instantly confirmed by{" "}
                {state.listing.merchantAccount.legalEntityName}
              </Typography>
            </Box>
          )}

          <SetupIntentComponent />
        </Grid>

        <DocumentViewerModal
          documentId={documentId}
          open={documentViewerOpen}
          setOpenFn={setDocumentViewerOpen}
        />

        {/* <pre>{JSON.stringify(state.listing, "", 2)}</pre> */}
      </WrapperContainer>
    </>
  );
}
