/**
 * @link https://www.npmjs.com/package/react-image-gallery
 */

import "react-image-gallery/styles/css/image-gallery.css";

import { Alert, LinearProgress } from "@mui/joy";
import { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import { useGetMediaQuery } from "../../services/rtk/osare";

export default function Gallery(props) {
  const [images, setImages] = useState([]);
  const {
    data: mediaData,
    isLoading,
    error,
  } = useGetMediaQuery({
    collection: props.collection,
    collectionId: props.collectionId,
  });

  useEffect(() => {
    if (mediaData && mediaData.results) {
      let images = [];
      mediaData.results.forEach((media) => {
        images.push({
          original: media.signedUrl,
          thumbnail: media.signedUrl,
        });
      });

      setImages(images);
    }
  }, [mediaData]);

  if (!props.collection || !props.collectionId) {
    console.warn("Missing a collection or collection ID for gallery");
    return null;
  }

  if (images.length === 0) {
    return null;
  }

  return (
    <>
      {isLoading && !error ? (
        <LinearProgress />
      ) : (
        <ImageGallery showPlayButton={false} items={images} />
      )}
      {error ? (
        <Alert severity="Warning">
          Sorry, we couldn't load the image gallery.
        </Alert>
      ) : null}
    </>
  );
}
