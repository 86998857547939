import { DateTime } from "luxon";

export function calculateDaysToPackageCharge(state) {
  const travelDate = DateTime.fromMillis(state.availabilityItem.fromTimestamp);
  const travelDateWithPolicy = travelDate.minus({
    days: state.listing.chargeRemainingAmountDaysBeforeTravel,
  });

  const daysTillCharge = travelDateWithPolicy.diffNow("days").days;

  return {
    travelDateWithPolicy,
    daysTillCharge,
  };
}
