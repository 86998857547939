const sampleData = {
  _id: "674457148cccb367cfc24ace",
  entity: "metimeaway",
  firebaseUserId: "4PtwaHJ32HWtdioHTIcLIi7Oa4u1",
  name: "Escape to Serenity: Your Modern Wellness Retreat",
  accommodationIds: ["674457148cccb367cfc24acc"],
  eventIds: ["674457148cccb367cfc24acd"],
  policyId: "TBD",
  commissionPercentage: 18,
  isActive: true,
  isReviewed: false,
  createdAt: 1732531988263,
  updatedAt: 1732897923475,
  askForInsurance: true,
  highlights: {
    blocks: [
      {
        key: "b2lvi",
        text: "Our modern wellness retreat offers a unique blend of relaxation, rejuvenation, and personal growth. Immerse yourself in a serene environment and indulge in a variety of wellness activities designed to nourish your mind, body, and soul.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "8f1el",
        text: "Highlights:",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "c3q1u",
        text: "Yoga and Meditation: Daily yoga and meditation classes to calm the mind and promote inner peace.",
        type: "unordered-list-item",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "am1cp",
        text: "Healthy Cuisine: Savor delicious, nutritious meals prepared by our expert chefs.",
        type: "unordered-list-item",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "d48uf",
        text: "Spa Treatments: Pamper yourself with a range of luxurious spa treatments, including massages, facials, and body scrubs.",
        type: "unordered-list-item",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "c7htb",
        text: "Fitness Classes: Stay active with invigorating fitness classes like Pilates, HIIT, and aqua aerobics.",
        type: "unordered-list-item",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "9g9k",
        text: "Nature Walks and Hiking: Explore the stunning natural surroundings and connect with nature.",
        type: "unordered-list-item",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "ck53k",
        text: "Wellness Workshops: Learn valuable tools and techniques for a healthier and happier life.",
        type: "unordered-list-item",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "23sn3",
        text: "Benefits:",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "faoo",
        text: "Reduced Stress and Anxiety: Experience a profound sense of calm and relaxation.",
        type: "unordered-list-item",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "2t3f8",
        text: "Improved Physical Health: Enhance your physical fitness and vitality.",
        type: "unordered-list-item",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "6fhn0",
        text: "Boosted Mental Clarity: Sharpen your focus and concentration.",
        type: "unordered-list-item",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "esd6",
        text: "Enhanced Emotional Well-being: Cultivate positive emotions and a greater sense of purpose.",
        type: "unordered-list-item",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "3ilgq",
        text: "Deep Sleep: Recharge your body and mind with restful sleep.",
        type: "unordered-list-item",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "580ua",
        text: "Increased Energy Levels: Feel energized and revitalized.",
        type: "unordered-list-item",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "28anc",
        text: "Our dedicated team of wellness experts is committed to providing you with a personalized experience tailored to your specific needs. Book your wellness retreat today and embark on a transformative journey towards a healthier and happier you.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  },
  introduction: {
    blocks: [
      {
        key: "367o4",
        text: "Discover a haven of tranquility and rejuvenation at our modern wellness retreat. Nestled amidst breathtaking natural beauty, our serene oasis offers a holistic approach to well-being. Unwind, recharge, and reconnect with your true self as you immerse yourself in a world of tranquility and rejuvenation.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  },
  tagline: "Escape to a modern wellness retreat to revive yourself and relax!",
  termsAndConditions: {
    blocks: [
      {
        key: "b4a85",
        text: "Fusce ac mauris auctor leo ultrices gravida. Curabitur at fringilla lorem, id dapibus magna. Aenean vestibulum dui magna, quis commodo enim iaculis quis. Vivamus elit mauris, convallis in massa et, rhoncus interdum justo. Quisque eget semper neque. Suspendisse cursus dui a neque volutpat, vehicula venenatis ante feugiat. Nulla vel sapien sed ipsum gravida gravida eu gravida elit. Morbi in consectetur felis. Nullam aliquet ante ante, ut aliquet ipsum gravida finibus. Etiam venenatis elit urna, sed mollis neque feugiat nec. Maecenas dictum accumsan quam et iaculis. Proin sollicitudin vulputate ante accumsan maximus. Donec sit amet turpis vel erat pellentesque elementum lobortis in turpis.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "704it",
        text: "Aliquam viverra faucibus felis, sed accumsan nulla. Nulla sit amet euismod tellus. Phasellus hendrerit ornare diam, vitae ornare ante sodales sit amet. Aenean sit amet neque ac neque feugiat ornare vel eu lacus. Duis pellentesque eget elit in pellentesque. Ut blandit laoreet cursus. Sed ullamcorper, sapien quis gravida viverra, ante odio congue velit, nec facilisis dui erat sed nulla.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "eq7jh",
        text: "Integer lacinia mauris eu pharetra sollicitudin. Fusce tempus odio et est pulvinar, at laoreet nisi porttitor. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed et elementum tellus. Etiam aliquam bibendum purus. Morbi eu rutrum dolor. Duis ac mauris eget nulla posuere ultricies. Maecenas in orci sit amet lectus iaculis fringilla. Donec sed hendrerit nulla. Sed vitae congue est. Etiam sed turpis sagittis, molestie metus quis, tempor arcu.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "1q3sq",
        text: "Nunc rutrum pretium dolor quis feugiat. Curabitur tincidunt aliquam tincidunt. In hac habitasse platea dictumst. Sed velit mi, dapibus at auctor at, lobortis a quam. Curabitur ut diam arcu. Donec tincidunt orci vitae mi imperdiet, sed imperdiet ante pretium. Nulla venenatis odio sit amet enim faucibus, sit amet molestie dolor placerat. Nunc metus metus, malesuada eu malesuada non, fermentum eu eros. Integer imperdiet, risus ac imperdiet volutpat, leo quam ultricies ligula, dapibus consequat turpis turpis vitae ipsum. Nunc ut magna non libero finibus vehicula eget id ante. Suspendisse at diam urna. Praesent et neque elementum, feugiat diam vitae, vehicula ante. Sed sagittis, metus a convallis suscipit, arcu nibh lobortis mi, non pellentesque enim nibh eu lacus.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  },
  cancellationRanges: [
    {
      day: 1,
      percentage: "50",
    },
    {
      day: "14",
      percentage: "25",
    },
  ],
  chargeRemainingAmountDaysBeforeTravel: 14,
  depositPercentage: 30,
  isChargingFullAmount: false,
  isDepositRefundable: false,
  accommodationData: [
    {
      _id: "674457148cccb367cfc24acc",
      entity: "metimeaway",
      firebaseUserId: "4PtwaHJ32HWtdioHTIcLIi7Oa4u1",
      name: "Modern Wellness Accommodation Hotel",
      description: {
        blocks: [
          {
            key: "ej6qp",
            text: "Our modern wellness retreat offers a serene escape to rejuvenate your mind, body, and soul. Immerse yourself in a tranquil environment and indulge in a variety of wellness activities, from yoga and meditation to fitness classes and spa treatments. Our expert practitioners will guide you on a personalized journey to optimal well-being. Savor delicious, healthy cuisine, explore stunning natural surroundings, and connect with like-minded individuals.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      directionsAdvisory: {
        entityMap: {},
        blocks: [],
      },
      faActivitiesFitness: [
        "Cardio machines",
        "Weightlifting equipment",
        "Yoga",
      ],
      faBasics: [
        "Wi-Fi internet access",
        "Television",
        "Hairdryer",
        "Safe",
        "Desk",
        "USB charging ports",
      ],
      faFoodDrink: ["Casual dining", "Coffee Shop", "Cocktail Lounge"],
      faIndoor: [
        "Fitness center or gym",
        "Spa or wellness center",
        "Games room",
      ],
      faOutdoor: ["Barbecue facilities", "Tennis courts", "Basketball courts"],
      faRentals: ["Scooters", "Mopeds", "Bicycles"],
      faServices: [
        "Laundry facilities",
        "Housekeeping",
        "24-hour front desk",
        "Business center",
        "Parking",
        "Concierge service",
      ],
      faSpaBeauty: [
        "Manicures",
        "Relaxation lounges",
        "Body scrubs",
        "Steam rooms",
        "Deep tissue massage",
      ],
      faSustainability: [
        "Renewable Energy",
        "Recycling Options",
        "Eco-Friendly Amenities",
        "Energy-Efficient Design",
      ],
      generalAdvisory: {
        entityMap: {},
        blocks: [],
      },
      geoJson: {
        coordinates: null,
        type: null,
      },
      insuranceAdvisory: {
        blocks: [
          {
            key: "4cv6o",
            text: "It is strongly recommended that all guests have comprehensive travel insurance to cover medical emergencies, trip cancellations, and other unforeseen circumstances. This insurance should include coverage for activities such as hiking, yoga, and other outdoor pursuits. Please ensure your policy covers the specific activities offered at the retreat.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      localAreaAdvisory: {
        entityMap: {},
        blocks: [],
      },
      location: {
        addressComponents: [
          {
            long_name: "Cotswolds",
            short_name: "Cotswolds",
            types: ["colloquial_area", "political"],
          },
          {
            long_name: "England",
            short_name: "England",
            types: ["administrative_area_level_1", "political"],
          },
          {
            long_name: "United Kingdom",
            short_name: "GB",
            types: ["country", "political"],
          },
        ],
        formattedAddress: "Cotswolds, UK",
        geometry: {
          lat: 51.79495199999999,
          lng: -1.8838872,
        },
        name: "Cotswolds",
        googlePlaceId: "ChIJBQY_ov88cUgRKv8JQ7qJjBY",
      },
      locationDescription: {
        blocks: [
          {
            key: "6n1m8",
            text: "The Cotswolds is a picturesque region in central England, renowned for its rolling hills, charming villages, and stunning countryside. Our wellness retreat is nestled amidst this idyllic landscape, offering a tranquil escape from the hustle and bustle of city life.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      media: [],
      seasonalAdvisory: {
        blocks: [
          {
            key: "7pnud",
            text: "The Cotswolds offers a unique and serene setting for a wellness retreat, with each season bringing its own charm and activities:",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "dn2rp",
            text: "Spring: Mild temperatures, blooming wildflowers, and opportunities for outdoor activities like hiking and cycling.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "d5avd",
            text: "Summer: Warm, sunny days perfect for exploring the picturesque villages and enjoying outdoor yoga and picnics.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "5n8n8",
            text: "Autumn: Vibrant fall foliage, cozy firesides, and opportunities for foraging and mindfulness walks.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "esquk",
            text: "Winter: Crisp, clear air, cozy winter walks, and the chance to experience the festive charm of Cotswold towns and villages.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "bbl2s",
            text: "No matter the season, you can expect stunning countryside views, peaceful tranquility, and opportunities to reconnect with nature and yourself.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      styles: ["Luxury", "Modern", "Eco-Friendly"],
      vaccinationsAdvisory: {
        blocks: [
          {
            key: "3sgcq",
            text: "No vaccination required.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      weatherAdvisory: {
        blocks: [
          {
            key: "cu37h",
            text: "Please note that the Cotswolds can experience variable weather, especially during the transitional seasons.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "65v2m",
            text: "Typical Weather:",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "597jm",
            text: "Spring (March-May): Mild temperatures, occasional showers, and blooming wildflowers.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "br9u",
            text: "Summer (June-August): Warm and sunny days, perfect for outdoor activities.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "d5bp9",
            text: "Autumn (September-November): Crisp, colorful foliage and cooler temperatures.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "9jpus",
            text: "Winter (December-February): Cold temperatures, potential for frost and snowfall, and cozy winter vibes.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      whatToBringAdvisory: {
        blocks: [
          {
            key: "4cfk",
            text: "Here's a checklist of items to bring to ensure a comfortable and enjoyable stay at our wellness retreat:",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "12ot1",
            text: "Comfortable Clothing: Yoga pants, t-shirts, sweatshirts, and comfortable shoes for various activities.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "fuval",
            text: "Swimwear: For pool or water-based activities.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "elup1",
            text: "Warm Clothing: Layers for cooler evenings and outdoor activities.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "fjr43",
            text: "Reusable Water Bottle: Stay hydrated throughout the day.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "3gfi2",
            text: "Sunscreen and Hat: Protect yourself from the sun.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "74t57",
            text: "Insect Repellent: To ward off bugs, especially during outdoor activities.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "aspjp",
            text: "Personal Medications: Bring any necessary medications.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "5nvn3",
            text: "Journal and Pen: For journaling and reflection.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
    },
  ],
  roomIds: ["6747ad86ce3f758b564e1bbb", "6747ad86ce3f758b564e1bbb"],
  roomData: [
    {
      _id: "6747ad86ce3f758b564e1bbb",
      entity: "metimeaway",
      firebaseUserId: "4PtwaHJ32HWtdioHTIcLIi7Oa4u1",
      media: [],
      amenities: {
        extras: [
          "Rain Shower",
          "Bluetooth Speaker",
          "In-Room Dining",
          "Smart Lighting",
          "High-Thread Count Cotton Sheets",
          "Candles and Aromatherapy Diffusers",
        ],
        basics: [
          "Desk",
          "Nightstands",
          "Wi-Fi (free)",
          "Pillow menu",
          "Safe",
          "Alarm Clock",
          "Wardrobe",
          "Television",
        ],
        bathroom: [
          "Shampoo",
          "Conditioner",
          "Dental Kit",
          "Bathtub",
          "Shower",
          "Mirror",
          "Shaving Kit",
          "Hairdryer",
        ],
        personalCare: [],
        foodPreparation: [],
        comfort: [],
        sustainability: [],
      },
      description: {
        blocks: [
          {
            key: "83me2",
            text: "Relax and rejuvenate in our modern wellness double room. This stylish and serene space is designed to promote well-being and tranquility. Enjoy the calming ambiance, featuring soft lighting, soothing colors, and contemporary furnishings.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "a9ojr",
            text: "Our wellness rooms are equipped with:",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "1nada",
            text: "In-room wellness amenities: Yoga mats, meditation cushions, and relaxation guides.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "718cf",
            text: "High-quality bedding: For a restful night's sleep.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "2k5q6",
            text: "Spacious bathroom: With luxurious bath products and a refreshing rain shower.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "8d79b",
            text: "Smart technology: Control room temperature, lighting, and curtains with a touch of a button.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "a7g6g",
            text: "Complimentary wellness drinks: Choose from a selection of herbal teas and infused water.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "99e8v",
            text: "Indulge in a truly restorative stay.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      amenitiesAvailable: true,
      sleepingArrangements: [
        {
          name: [
            {
              type: "heading1",
              text: "Queen Bed",
              spans: [],
              direction: "ltr",
            },
          ],
          occupant_count: 2,
          description: [
            {
              type: "paragraph",
              text: "A bed larger than a double bed, providing more space for two people.",
              spans: [],
              direction: "ltr",
            },
          ],
          icon_name: [],
          image: {},
        },
      ],
      name: "Modern Wellness Double Room",
      accommodationId: "674457148cccb367cfc24acc",
      isDeleted: false,
      isReviewed: false,
    },
  ],
  eventData: [
    {
      _id: "674457148cccb367cfc24acd",
      entity: "metimeaway",
      firebaseUserId: "4PtwaHJ32HWtdioHTIcLIi7Oa4u1",
      name: "Event created by the Wizard on November 25, 2024 at 10:53 AM UTC",
      categories: ["Luxury", "Restorative", "Wellness", "Meditation"],
      checkInTime: "12:00",
      checkOutTime: "10:00",
      eventDaysLength: 2,
      expectedExperience: {
        entityMap: null,
        blocks: null,
      },
      geoJson: {
        coordinates: null,
        type: null,
      },
      location: {
        geometry: {
          lng: null,
          lat: null,
        },
        formattedAddress: null,
        addressComponents: null,
      },
      maximumGroupSize: 10,
      minimumGroupSize: 1,
      spokenLanguages: ["English"],
      staffIds: ["6744545b59d05f8b32f4b439"],
      itineraryOverview: {
        blocks: [
          {
            key: "46qmg",
            text: "Indulge in a transformative wellness experience at our serene retreat. Immerse yourself in a tranquil environment and rejuvenate your mind, body, and soul. Our holistic approach to wellness offers a range of activities designed to promote relaxation, revitalization, and personal growth.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      maximumAge: 99,
      minimumAge: 18,
      skillLevels: ["Beginner"],
      dietarySupport: ["Gluten Free", "Halal", "Vegan", "Vegetarian"],
      includedDrinks: ["Water", "Tea", "Coffee"],
      includedMeals: ["Breakfast", "Lunch", "Dinner"],
      overallFoodDescription: {
        blocks: [
          {
            key: "fl8pq",
            text: "Indulge your senses with our wholesome and delicious cuisine. Each meal is carefully crafted to nourish your body and soul, featuring fresh, locally-sourced ingredients. Start your day with a vibrant breakfast of organic fruits, yogurt, and whole-grain toast. For lunch, savor a light and refreshing salad or a hearty soup. In the evening, enjoy gourmet dinners featuring plant-based dishes, fresh seafood, and lean proteins. Our culinary team will also cater to dietary restrictions and preferences, ensuring that every meal is a delightful experience.",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      itineraryDayByDay: [
        {
          blocks: [
            {
              key: "43v1q",
              text: "Morning:",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
            {
              key: "16qto",
              text: "7:00 AM: Wake up to a gentle yoga class, followed by a meditation session.",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
            {
              key: "bc7rg",
              text: "8:30 AM: Enjoy a nutritious breakfast buffet, featuring fresh fruits, organic yogurt, and whole-grain options.",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
            {
              key: "ed3dh",
              text: "9:30 AM: Embark on a guided nature walk, taking in the serene beauty of the surrounding landscape.",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
            {
              key: "1ven2",
              text: "Afternoon:",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
            {
              key: "fljn7",
              text: "12:00 PM: Indulge in a delicious and healthy lunch, prepared with fresh, locally-sourced ingredients.",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
            {
              key: "7vqb",
              text: "1:30 PM: Relax and rejuvenate with a spa treatment of your choice, such as a massage or facial.",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
            {
              key: "90qqa",
              text: "3:00 PM: Participate in a wellness workshop, such as stress management or mindfulness techniques.",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
            {
              key: "f8mik",
              text: "Evening:",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
            {
              key: "44agj",
              text: "6:00 PM: Enjoy a gourmet dinner, featuring a variety of healthy and flavorful dishes.",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
            {
              key: "604oq",
              text: "7:30 PM: Wind down with a relaxing yoga nidra session, followed by a quiet evening of meditation or reading.",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
          ],
          entityMap: {},
        },
        {
          blocks: [
            {
              key: "3k924",
              text: "Morning Yoga: Start your day with a peaceful yoga session.",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
            {
              key: "f9sel",
              text: "Healthy Breakfast: Enjoy a nourishing breakfast to fuel your day.",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
            {
              key: "9a478",
              text: "Mindfulness Meditation: Practice mindfulness techniques to reduce stress and anxiety.",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
            {
              key: "2m6am",
              text: "Check-out: Depart feeling refreshed, rejuvenated, and inspired.",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
          ],
          entityMap: {},
        },
      ],
      preTravelAdvisory: {
        blocks: [
          {
            key: "e3k7f",
            text: "Travel Tips:",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "a7idm",
            text: "Booking Your Flight: Book your flight to the nearest airport to the retreat center.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "au3ri",
            text: "Transportation: Arrange for transportation from the airport to the retreat center. We can assist with booking a taxi or car service.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "ad8hl",
            text: "Visa Requirements: Ensure you have the necessary visa to enter the country.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
      specificallyExcluded: [
        {
          description: "Transportation",
        },
        {
          description: "Insurance",
        },
        {
          description: "Gratuities",
        },
      ],
      specificallyIncluded: [
        {
          description: "Tai Chi and Qigong",
        },
        {
          description: "Sound Baths",
        },
        {
          description: "Art Therapy",
        },
        {
          description: "One-on-One Consultations",
        },
        {
          description: "Bonfires",
        },
      ],
      thingsToDoPaid: {
        blocks: [
          {
            key: "5t46i",
            text: "Additional Treatments:",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "2233h",
            text: "Advanced Spa Treatments: Such as cryotherapy, infrared saunas, or specialized facials.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "4rq8m",
            text: "Alternative Therapies: Like acupuncture, reiki, or aromatherapy.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "2so8k",
            text: "Personalized Health Consultations: With nutritionists, health coaches, or medical professionals.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "brc63",
            text: "Experiences:",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "9a215",
            text: "Guided Meditation Retreats: Deepen your meditation practice with expert guidance.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "ashep",
            text: "Yoga Teacher Training: Immerse yourself in a yoga teacher training program.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "69gvm",
            text: "Culinary Workshops: Learn to cook healthy and delicious meals.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "dgk3",
            text: "Art and Creativity Workshops: Unleash your creative side through painting, drawing, or journaling.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "8q043",
            text: "Outdoor Adventures: Hiking, biking, surfing, or kayaking.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "bb55q",
            text: "Additional Services:",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "jhnk",
            text: "Personal Training: One-on-one fitness training sessions.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "ett42",
            text: "Private Yoga Sessions: Tailored yoga sessions in the privacy of your room or a secluded outdoor space.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: "d8ejm",
            text: "In-Room Spa Treatments: Enjoy spa treatments in the comfort of your own room.",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      },
    },
  ],
  merchantAccount: {
    defaultCurrency: "gbp",
    businessName: "Digital Industria Ltd",
    legalEntityName: "Digital Industria Ltd",
    stripeAccountId: "acct_1QOprdIOm3TaVCBo",
    websiteAddress: "https://digitalindustria.com",
    supportWebsiteAddress: null,
    supportAddress: null,
    supportEmail: null,
    supportPhone: null,
  },
  availabilityData: [
    {
      _id: "6749b6aec6f084a7bdfc85a6",
      fromTimestamp: 1733443200000,
      firebaseUserId: "4PtwaHJ32HWtdioHTIcLIi7Oa4u1",
      toTimestamp: 1733616000000,
      associatedAccommodationId: "674457148cccb367cfc24acc",
      entity: "metimeaway",
      associatedListingId: "674457148cccb367cfc24ace",
      associatedEventId: "674457148cccb367cfc24acd",
      inventory: [
        {
          _id: "6749b6aec6f084a7bdfc85a7",
          firebaseUserId: "4PtwaHJ32HWtdioHTIcLIi7Oa4u1",
          associatedAccommodationId: "674457148cccb367cfc24acc",
          entity: "metimeaway",
          associatedAvailabilityId: "6749b6aec6f084a7bdfc85a6",
          associatedListingId: "674457148cccb367cfc24ace",
          associatedRoomId: "6747ad86ce3f758b564e1bbb",
          associatedEventId: "674457148cccb367cfc24acd",
          defaultCurrency: "GBP",
          foreignDocumentId: "6747ad86ce3f758b564e1bbb",
          isShareable: false,
          price: 1,
          quantity: 5,
          quantityPurchased: 0,
          type: "room",
          meta: {},
          isActive: true,
        },
      ],
    },
    {
      _id: "674dc088c6f084a7bdfc85d8",
      associatedListingId: "674457148cccb367cfc24ace",
      associatedEventId: "674457148cccb367cfc24acd",
      fromTimestamp: 1733235170023,
      firebaseUserId: "4PtwaHJ32HWtdioHTIcLIi7Oa4u1",
      toTimestamp: 1733407970024,
      associatedAccommodationId: "674457148cccb367cfc24acc",
      entity: "metimeaway",
      inventory: [
        {
          _id: "674dc088c6f084a7bdfc85d9",
          firebaseUserId: "4PtwaHJ32HWtdioHTIcLIi7Oa4u1",
          associatedAccommodationId: "674457148cccb367cfc24acc",
          entity: "metimeaway",
          associatedAvailabilityId: "674dc088c6f084a7bdfc85d8",
          associatedListingId: "674457148cccb367cfc24ace",
          associatedRoomId: "6747ad86ce3f758b564e1bbb",
          associatedEventId: "674457148cccb367cfc24acd",
          defaultCurrency: "GBP",
          foreignDocumentId: "6747ad86ce3f758b564e1bbb",
          isShareable: false,
          price: 10,
          quantity: 5,
          quantityPurchased: 0,
          type: "room",
          meta: {},
          isActive: true,
        },
      ],
    },
  ],
  config: {
    name: "Me Time Away",
    sites: {
      dashboard: {
        url: "https://dashboard.osare.io",
        stripeReturnUrl: "https://dashboard.osare.io/onboard",
        stripeRefreshUrl: "https://dashboard.osare.io/onboard",
      },
      bookings: {
        url: "https://bookings.osare.io",
      },
    },
    contacts: {
      website: "metimeaway.com",
      support: {
        email: "support@metimeaway.com",
        phone: "",
        website: "",
      },
    },
    notifications: {
      types: {
        merchant: {
          senderDomain: "merchantupdates.metimeaway.com",
          senderFromAddress:
            "Me Time Away Merchant Updates <no-reply@merchantupdates.metimeaway.com>",
        },
        customer: {
          senderDomain: "updates.metimeaway.com",
          senderFromAddress:
            "Me Time Away Updates <no-reply@updates.metimeaway.com>",
        },
      },
    },
    legal: {
      businessName: "Osare.io Ltd",
      companyNumber: "12719591",
      signerName: "Kieran Goodary",
      signerPosition: "Director",
      signerEmail: "osareplatform@gmail.com",
      address: {
        line1: "18 Calverley Road",
        line2: "Epsom",
        city: "Surrey",
        postal_code: "KT17 2NX",
        country: "United Kingdom",
      },
    },
  },
};

export default sampleData;
