import {
  Box,
  Button,
  Container,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import algoliasearch from "algoliasearch/lite";
import {
  Hits,
  InstantSearch,
  RefinementList,
} from "react-instantsearch-hooks-web";
import { useNavigate } from "react-router-dom";

const searchClient = algoliasearch(
  process.env[`REACT_APP_PUBLIC_METIMEAWAY_ALGOLIA_APP_ID`],
  process.env[`REACT_APP_PUBLIC_METIMEAWAY_ALGOLIA_SEARCH_KEY`]
);

function Hit({ hit }) {
  const navigate = useNavigate();

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }} variant="outlined">
            <Typography>{hit.name}</Typography>
            <Typography>{hit.tagline}</Typography>
            <Button onClick={() => navigate(`/listing?listingId=${hit._id}`)}>
              Continue
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function Browse() {
  return (
    <InstantSearch searchClient={searchClient} indexName="listings">
      <Container>
        <Grid container>
          <Grid item xs={2}>
            <FormGroup>
              <FormLabel>Skill Level</FormLabel>
              <RefinementList attribute="inventory.events.eventData.skillLevels" />
            </FormGroup>

            <FormGroup>
              <FormLabel>Retreat Category</FormLabel>
              <RefinementList attribute="inventory.events.eventData.categories" />
            </FormGroup>

            <FormGroup>
              <FormLabel>Location</FormLabel>
              <RefinementList attribute="inventory.events.eventData.location.addressComponents.short_name" />
            </FormGroup>

            <FormGroup>
              <FormLabel>Food</FormLabel>
              <RefinementList attribute="inventory.events.eventData.dietarySupport" />
            </FormGroup>
          </Grid>

          <Grid item xs={10}>
            <Hits style={{ listStyleType: "none" }} hitComponent={Hit} />
          </Grid>
        </Grid>
      </Container>
    </InstantSearch>
  );
}
