import { Box, Divider, Grid, LinearProgress } from "@mui/joy";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";

export default function WrapperContainer(props) {
  const auth = getAuth();
  const [loggedInUser, setLoggedInUser] = useState(false);

  useEffect(() => {
    const findUser = () => {
      auth.onAuthStateChanged((auth) => {
        if (
          !auth &&
          window.location.pathname !== "/login" &&
          window.location.pathname !== "/listing"
        ) {
          window.location.href = "/login";
        } else {
          setLoggedInUser(auth);
        }
      });
    };

    findUser();
  }, [auth]);

  return (
    <Grid container>
      <Grid md={3}></Grid>
      <Grid xs={12} md={6}>
        {loggedInUser ? (
          props.children
        ) : (
          <Box textAlign={"center"}>
            <LinearProgress />
          </Box>
        )}
      </Grid>
      <Grid md={3}></Grid>

      <Grid xs={12} marginY={5}>
        <Divider>&copy; Osare.io Ltd {new Date().getFullYear()}</Divider>
      </Grid>
    </Grid>
  );
}
