import { Typography } from "@mui/joy";

export default function Home() {
  return (
    <>
      <Typography level="h1" textAlign={"center"}>
        Osare Booking System
      </Typography>
    </>
  );
}
