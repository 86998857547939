import { Bed } from "@mui/icons-material";
import { Typography } from "@mui/joy";
import _ from "lodash";

export default function SleepingArrangment(props) {
  // Workout sleeping arrangements UI
  const sleepingArrangements = props.sleepingArrangementsData.map((sa) => {
    return (
      <Typography
        startDecorator={<Bed />}
        key={`sa-${sa.name[0].text}-${_.uniqueId()}`}
        level="body-md"
        component={"div"}
      >
        {sa.name[0].text} - Sleeps {sa.occupant_count}
      </Typography>
    );
  });

  return sleepingArrangements;
}
