import { Warning } from "@mui/icons-material";
import {
  Alert,
  Button,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useDoCancellationMutation } from "../../services/rtk/osare";

export default function CancellationModal(props) {
  const [triggerDoCancellation, doCancellationResults] =
    useDoCancellationMutation();

  const askToConfirmCancellation = () => {
    if (
      window.confirm(
        "Are you sure you want to cancel this booking? This action cannot be undone!"
      )
    ) {
      triggerDoCancellation({ orderId: props.state._id });
    }
  };

  useEffect(() => {
    if (doCancellationResults.isSuccess) {
      props.openFn(false);
      props.orderRefetchFn();
    }
    if (doCancellationResults.isError) {
      alert(
        `Sorry, an error occured whilst trying to cancel the booking:\n\n${doCancellationResults.error.data.errors.join(
          ", "
        )}\n\nPlease try again, or contact our support team if the problem persists.`
      );
    }
  }, [doCancellationResults, props]);
  // const [getCancellationQuote, cancellationQuoteResponse] =
  //   useLazyGetCancellationQuoteQuery();
  // const [executeCancellation, executeCancellationResponse] =
  //   useExecuteCancellationMutation();

  // useEffect(() => {
  //   if (executeCancellationResponse.isSuccess) {
  //     props.openFn(false);
  //     props.orderRefetchFn();
  //   }
  // }, [executeCancellationResponse, props]);

  // useEffect(() => {
  //   console.log(props.open);
  //   if (props.open) {
  //     getCancellationQuote({ orderId: props.orderId });
  //   }
  // }, [getCancellationQuote, props.open, props.orderId]);

  // const executeCancellationHandler = () => {
  //   // eslint-disable-next-line no-restricted-globals
  //   if (confirm("Are you sure you want to cancel this booking?")) {
  //     executeCancellation({
  //       quoteId: cancellationQuoteResponse.data.cancellationInsert.insertedId,
  //     });
  //   }
  // };

  // const CancellationOptions = () => {
  //   console.log("props", props);
  //   return (
  //     <DialogContent>
  //       <Typography gutterBottom>
  //         This is the policy that was attached to the booking:
  //       </Typography>
  //       <Card>
  //         <SimplePolicy policyData={props.state.listingData.policyData} />
  //       </Card>
  //       {cancellationQuoteResponse.isFetching && (
  //         <>
  //           <Typography gutterBottom>
  //             We're just checking your cancellation options...
  //           </Typography>
  //           <LinearProgress />
  //         </>
  //       )}
  //       {cancellationQuoteResponse.isError && (
  //         <Box marginTop={2}>
  //           <Alert color="danger">
  //             Sorry, an error occured whilst trying to fetch your cancenllation
  //             options. Please try again.
  //           </Alert>
  //         </Box>
  //       )}

  //       {cancellationQuoteResponse.isSuccess && (
  //         <>
  //           {cancellationQuoteResponse.isSuccess &&
  //             cancellationQuoteResponse.data.cancellationData
  //               .wasPaymentStillPending && (
  //               <Box marginTop={2}>
  //                 <Card color="success" variant="soft">
  //                   <Typography level="body-lg" textAlign={"center"}>
  //                     The remaining payment for this booking hasn't been taken
  //                     yet, so this booking can be cancelled with no charge.
  //                   </Typography>
  //                 </Card>

  //                 <Box marginTop={2}>
  //                   <Button
  //                     onClick={() => executeCancellationHandler()}
  //                     color="danger"
  //                     variant="soft"
  //                     fullWidth
  //                   >
  //                     Cancel
  //                   </Button>
  //                 </Box>
  //               </Box>
  //             )}

  //           {cancellationQuoteResponse.isSuccess &&
  //             !cancellationQuoteResponse.data.cancellationData
  //               .wasPaymentStillPending && (
  //               <Box marginTop={2}>
  //                 {!cancellationQuoteResponse.data.cancellationData
  //                   .refundsAllowed ? (
  //                   <>
  //                     <Card color="danger" variant="outlined">
  //                       <Typography level="body-lg" textAlign={"center"}>
  //                         Sorry, the organiser's policy does not allow refunds.
  //                       </Typography>
  //                     </Card>

  //                     <Box marginTop={2}>
  //                       <Button
  //                         onClick={() => executeCancellationHandler()}
  //                         color="danger"
  //                         variant="solid"
  //                         fullWidth
  //                       >
  //                         Cancel booking
  //                       </Button>
  //                     </Box>
  //                   </>
  //                 ) : (
  //                   <>
  //                     <Card color="primary" variant="outlined">
  //                       <Typography
  //                         marginBottom={-2}
  //                         level="body-lg"
  //                         textAlign={"center"}
  //                       >
  //                         Refund amount:
  //                       </Typography>
  //                       <Typography
  //                         fontFamily={"monospace"}
  //                         fontSize={52}
  //                         textAlign={"center"}
  //                       >
  //                         {
  //                           cancellationQuoteResponse.data.listingData
  //                             .merchantAccount.defaultCurrency
  //                         }{" "}
  //                         {
  //                           cancellationQuoteResponse.data.cancellationData
  //                             .refundAmount
  //                         }
  //                       </Typography>
  //                     </Card>

  //                     <Box marginTop={2}>
  //                       <Button
  //                         onClick={() => executeCancellationHandler()}
  //                         color="danger"
  //                         variant="soft"
  //                         fullWidth
  //                       >
  //                         Refund and cancel
  //                       </Button>
  //                     </Box>
  //                   </>
  //                 )}
  //               </Box>
  //             )}
  //         </>
  //       )}
  //     </DialogContent>
  //   );
  // };

  // const ProcessingCancellation = () => {
  //   return (
  //     <DialogContent>
  //       <Box marginY={2} textAlign={"center"}>
  //         <CircularProgress />
  //       </Box>
  //       <Typography gutterBottom textAlign={"center"}>
  //         Working...
  //       </Typography>
  //     </DialogContent>
  //   );
  // };

  // const CancellationError = () => {
  //   return (
  //     <DialogContent>
  //       <Box marginY={2} textAlign={"center"}>
  //         <SvgIcon size="lg" color="warning" sx={{ transform: "scale(2.0)" }}>
  //           <Warning />
  //         </SvgIcon>
  //       </Box>
  //       <Typography level="body-lg" textAlign={"center"}>
  //         There was an issue whilst checking the cancellation options: <br />
  //         <b>{cancellationQuoteResponse.error.data.errors[0]}</b>
  //       </Typography>
  //     </DialogContent>
  //   );
  // };

  const ShowCancellationOptions = () => {
    return (
      <DialogContent>
        <Typography gutterBottom>
          We're sorry you're looking to cancel your booking. Here are the
          cancellation refund options for this booking:
        </Typography>
        <hr />
        {props.state.listingData.cancellationRanges.map((range, idx) => (
          <Typography key={idx}>
            {range.day} day{Number(range.day) > 1 ? "s" : ""} after booking
            date: {range.percentage}% refund
          </Typography>
        ))}
        <Typography color="warning">
          After day {props.state.listingData.cancellationRanges.length} from
          when the booking was made, no refunds will be given.
        </Typography>

        <hr />

        {DateTime.fromMillis(props.state.createdAt).diffNow().as("days") >
        props.state.listingData.cancellationRanges.length ? (
          <Alert startDecorator={<Warning />} color="danger" variant="soft">
            <Typography>
              This order was made on{" "}
              {DateTime.fromMillis(props.state.createdAt).toLocaleString(
                DateTime.DATETIME_MED_WITH_WEEKDAY
              )}{" "}
              which was{" "}
              {DateTime.fromMillis(props.state.createdAt).toRelative()} .
            </Typography>
            <Typography>
              You can cancel the booking, but unfortunately the organisers
              cancellation policy won't allow you to be eligible for a refund as
              the cancellation period now has passed.
            </Typography>
          </Alert>
        ) : (
          <>
            <Alert variant="soft" color="primary">
              <div>
                <Typography gutterBottom fontWeight={"bold"}>
                  This order was made on{" "}
                  {DateTime.fromMillis(props.state.createdAt).toLocaleString(
                    DateTime.DATETIME_MED_WITH_WEEKDAY
                  )}{" "}
                  which was{" "}
                  {DateTime.fromMillis(props.state.createdAt).toRelative()} .
                </Typography>
                <Typography>
                  You are within the cancellation period and can cancel this
                  booking in according to the organisers refund policy.
                </Typography>
              </div>
            </Alert>
            <hr />
            <Button
              color="danger"
              variant="soft"
              onClick={() => askToConfirmCancellation()}
            >
              Cancel Booking
            </Button>
          </>
        )}
      </DialogContent>
    );
  };

  return (
    <Modal onClose={() => props.openFn(false)} open={props.open}>
      <ModalDialog>
        <ModalClose />
        <DialogTitle>Cancellation Options</DialogTitle>
        {doCancellationResults.isLoading ? (
          <LinearProgress />
        ) : (
          <ShowCancellationOptions />
        )}
        {/* {executeCancellationResponse.isLoading ||
        cancellationQuoteResponse.isFetching ? (
          <ProcessingCancellation />
        ) : cancellationQuoteResponse.isError ? (
          <CancellationError />
        ) : (
          <CancellationOptions {...props} />
        )} */}
      </ModalDialog>
    </Modal>
  );
}
