// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

export const init = () => {
  const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
  const analytics = getAnalytics(app);
  const auth = getAuth(app);

  return {
    app,
    analytics,
    auth,
  };
};
