import "@fontsource/inter";
import * as Sentry from "@sentry/react";

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Browse from "./screens/Browse/Browse";
import Confirmed from "./screens/Confirmed/Confirmed";
import Home from "./screens/Home/Home";
import Listing from "./screens/Listing/Listing";
import Login from "./screens/Login/Login";
import Review from "./screens/Review/Review";
import { init } from "./services/firebase";
import { store } from "./store";

import { Wrapper } from "@googlemaps/react-wrapper";
import MyBookings from "./screens/My/Bookings/Bookings";
import MyBookingViewer from "./screens/My/Bookings/View";
import ThemedListing from "./themes/metimeaway/listing";

Sentry.init({
  enabled: window.location.hostname !== "localhost",
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^127.0.0.1/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

init();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/browse",
    element: <Browse />,
  },
  {
    path: "/listing",
    element: <Listing />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/review",
    element: <Review />,
  },
  {
    path: "/confirmed",
    element: <Confirmed />,
  },
  {
    path: "/my/bookings",
    element: <MyBookings />,
  },
  {
    path: "/my/bookings/view/:orderId",
    element: <MyBookingViewer />,
  },
  {
    path: "/metimeaway/listing",
    element: <ThemedListing />,
  },
  {
    path: "*",
    element: <Browse />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Wrapper apiKey={"AIzaSyBUflO7GfxzOqIT-ur40GGv2va9zMQiAic"}>
        <RouterProvider router={router} />
      </Wrapper>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
