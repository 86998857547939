import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Card, CircularProgress, Divider } from "@mui/joy";
import { getAuth } from "firebase/auth";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ResponsiveAppBar from "../../../components/Appbar/Appbar";
import BookingAccommodation from "../../../components/BookingAccommodation/BookingAccommodation";
import BookingEvent from "../../../components/BookingEvent/BookingEvent";
import BookingSummary from "../../../components/BookingSummary/BookingSummary";
import PaymentsSchedule from "../../../components/PaymentsSchedule/PaymentsSchedule";
import WrapperContainer from "../../../components/WrapperContainer/WrapperContainer";
import { useGetStorefrontUserOrderByIdQuery } from "../../../services/rtk/osare";

export default function MyBookingViewer() {
  const { state } = useLocation();
  const auth = getAuth();
  const params = useParams();
  const navigate = useNavigate();

  const {
    data: orderData,
    isLoading,
    isFetching,
    isSuccess,
    error,
    refetch,
  } = useGetStorefrontUserOrderByIdQuery({ id: params.orderId });

  // const {
  //   data: listingsData,
  //   isLoading: isLoadingListings,
  //   isFetching: isFetchingListings,
  //   refetch: listingsRefetch,
  // } = useGetStorefrontUserOrderListingsQuery();

  // useEffect(() => {
  //   console.log("orderData", orderData);
  //   if (!orderData) {
  //     refetch();
  //   }
  // }, [orderData]);

  /**
   * Initial load
   */
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      // When a user exists, perform the call
      if (user) {
        refetch();
      }
    });
  }, [auth, refetch]);

  // console.log(state);
  // console.log(params);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  const Loading = () => {
    return (
      <Box textAlign={"center"} marginTop={2}>
        <CircularProgress />
      </Box>
    );
  };

  return (
    <WrapperContainer>
      <ResponsiveAppBar />
      {!orderData ? (
        <Loading />
      ) : (
        <>
          <Box marginBottom={2}>
            <Button
              variant="plain"
              onClick={() => {
                navigate("/my/bookings");
              }}
              startDecorator={<ArrowBackIcon />}
            >
              Back to Bookings
            </Button>
          </Box>

          <Card>
            <BookingSummary orderData={orderData.results[0]} />
            <Divider>Accommodation</Divider>

            <BookingAccommodation {...orderData.results[0]} />
            <Divider>Event</Divider>
            <BookingEvent {...orderData.results[0]} />
            <PaymentsSchedule
              {...orderData.results[0]}
              orderRefetch={refetch}
            />
          </Card>
        </>
      )}
    </WrapperContainer>
  );
}
