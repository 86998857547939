import { Alert, Box, Button, Card, Divider, Link, Typography } from "@mui/joy";
import { DateTime } from "luxon";
import { useState } from "react";
import CancellationModal from "../CancellationModal/CancellationModal";

export default function PaymentsSchedule(props) {
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false);

  const SinglePaymentScheduleItem = (scheduledPaymentData) => {
    return (
      <Box marginBottom={2}>
        <Card
          color={
            scheduledPaymentData.status === "succeeded" ? "success" : "neutral"
          }
        >
          {scheduledPaymentData.onOrAfterTimestampWasAdjustedToNow && (
            <Alert color="warning">
              This payment date was adjusted because the organiser's selected
              policy charges you the remaining package fee before the date of
              booking, so this charge was moved up to the date of booking.
            </Alert>
          )}
          <Typography fontWeight={"bold"}>
            {scheduledPaymentData.purpose === "platformAndDepositFee"
              ? "Platform Fee & Deposit Fee: "
              : "Package Fee: "}
            {scheduledPaymentData.status}
          </Typography>
          <Typography marginBottom={-2} fontSize={21} fontFamily={"monospace"}>
            GBP {scheduledPaymentData.chargeAmount}
          </Typography>
          <Typography>
            {scheduledPaymentData.status === "succeeded" && "Paid on: "}
            {scheduledPaymentData.status === "refunded" && "Paid on: "}
            {scheduledPaymentData.status === "pending" && "Due on: "}
            {scheduledPaymentData.status === "cancelled" && "Was due on: "}
            {DateTime.fromMillis(
              scheduledPaymentData.onOrAfterTimestamp
            ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
          </Typography>
          {/* {scheduledPaymentData.isCancelled ? (
            <Chip color="danger" size="lg">
              Payment status: Cancelled
            </Chip>
          ) : (
            <Chip color="primary" size="lg">
              Payment status: <b>{scheduledPaymentData.status}</b>
            </Chip>
          )} */}

          {scheduledPaymentData.refundedAmount > 0 && (
            <Typography
              color="success"
              marginBottom={-2}
              fontSize={21}
              fontFamily={"monospace"}
            >
              Refunded: GBP {scheduledPaymentData.refundedAmount}
            </Typography>
          )}

          {scheduledPaymentData.receiptUrl ? (
            <Typography>
              <Link href={scheduledPaymentData.receiptUrl} target="_blank">
                View Receipt
              </Link>
            </Typography>
          ) : null}

          {scheduledPaymentData.purpose === "remainingPackageFee" && (
            <>
              <CancellationModal
                state={props}
                orderId={props._id}
                openFn={setIsCancellationModalOpen}
                open={isCancellationModalOpen}
                orderRefetchFn={props.orderRefetch}
              />
              <Button onClick={() => setIsCancellationModalOpen(true)}>
                See cancellation options
              </Button>
            </>
          )}
        </Card>
      </Box>
    );
  };

  return (
    <>
      <Box>
        <Divider>Payments</Divider>

        {props.scheduledPayments.map((payment) => (
          <SinglePaymentScheduleItem key={payment._id} {...payment} />
        ))}
      </Box>
    </>
  );
}
