import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import draftToHtml from "draftjs-to-html";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ChipCluster from "../ChipCluster/ChipCluster";
import Gallery from "../Gallery/Gallery";
import SleepingArrangment from "../SleepingArrangement/SleepingArrangement";

export default function RoomBlock(props) {
  // Deconstruct required variables
  const {
    listing,
    inventoryItem,
    stripeCustomerId,
    merchantStripeAccountId,
    availabilityItem,
  } = props;

  // Room data
  const roomData = listing.roomData.find(
    (room) => room._id === inventoryItem.associatedRoomId
  );

  const navigate = useNavigate();
  const [selectedOccupancy, setSelectedOccupancy] = useState(0);

  const forwardToReviewScreen = () => {
    if (selectedOccupancy === 0) {
      alert("Please select the quantity of a room to continue.");
      return;
    }

    navigate("/review", {
      state: {
        availabilityItem,
        inventoryItem,
        listingId: listing._id,
        accommodationId: inventoryItem.associatedAccommodationId,
        roomId: inventoryItem.associatedRoomId,
        roomQuantity: selectedOccupancy,
        stripeCustomerId,
        merchantStripeAccountId,
        config: listing.config,
        listing,
      },
    });
  };

  return (
    <Card
      // color="primary"
      variant="soft"
      key={`accommodation-${inventoryItem.associatedAccommodationId}-${inventoryItem.meta.roomId}`}
      sx={{ p: 2, marginBottom: 2 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography gutterBottom level="title-lg">
            {roomData.name}{" "}
            {inventoryItem.isShareable ? (
              <Chip color="primary">Shared</Chip>
            ) : null}
          </Typography>
          <SleepingArrangment
            sleepingArrangementsData={roomData.sleepingArrangements}
          />

          <Box marginY={2}>
            <Gallery collection="rooms" collectionId={roomData._id} />
          </Box>

          <Typography level="body-sm">
            <div
              dangerouslySetInnerHTML={{
                __html: draftToHtml(roomData.description),
              }}
            />
          </Typography>

          {roomData.amenities.sustainability &&
            roomData.amenities.sustainability.length > 0 && (
              <Box marginY={2}>
                <Typography level="body-md">Sustainability</Typography>
                <ChipCluster dataArray={roomData.amenities.sustainability} />
              </Box>
            )}

          {roomData.amenities.comfort &&
            roomData.amenities.comfort.length > 0 && (
              <Box marginY={2}>
                <Typography level="body-md">Comfort & Essentials</Typography>
                <ChipCluster dataArray={roomData.amenities.comfort} />
              </Box>
            )}

          {roomData.amenities.foodPreparation &&
            roomData.amenities.foodPreparation.length > 0 && (
              <Box marginY={2}>
                <Typography level="body-md">Food & Drink Facilities</Typography>
                <ChipCluster dataArray={roomData.amenities.foodPreparation} />
              </Box>
            )}

          {roomData.amenities.personalCare &&
            roomData.amenities.personalCare.length > 0 && (
              <Box marginY={2}>
                <Typography level="body-md">Personal Care</Typography>
                <ChipCluster dataArray={roomData.amenities.personalCare} />
              </Box>
            )}

          <Card variant="soft" color="success">
            <Typography fontSize={25} fontFamily={"monospace"}>
              {inventoryItem.defaultCurrency} {inventoryItem.price}
            </Typography>
            <Typography marginTop={-2} level="body-xs">
              Includes fees and charges. Exluding taxes.
            </Typography>

            <Select
              fullWidth
              value={selectedOccupancy}
              onChange={(e, v) => {
                console.log(v);
                setSelectedOccupancy(v);
              }}
            >
              <Option selected disabled value={0}>
                Please select a quantity
              </Option>
              {Array.from(
                Array(inventoryItem.quantity - inventoryItem.quantityPurchased)
              ).map((e, i) => (
                <Option value={i + 1}>{i + 1}</Option>
              ))}
            </Select>

            <Button
              color="success"
              disabled={selectedOccupancy === 0}
              fullWidth
              onClick={() => forwardToReviewScreen()}
            >
              {selectedOccupancy === 0
                ? "Please select a quantity first"
                : "Continue..."}
            </Button>
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
}
